'use client'

import { createContext, useContext } from 'react'
import { useToggle } from 'react-use'

import { FeedbackModal } from '../ui/FeedbackModal'

const FeedbackModalContext = createContext({
  feedbackModalIsOpen: false,
  onFeedbackModalChange: (): void => {},
})

export const FeedbackModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [on, toggle] = useToggle(false)

  return (
    <FeedbackModalContext.Provider
      value={{ feedbackModalIsOpen: on, onFeedbackModalChange: toggle }}
    >
      {children}

      <FeedbackModal isOpen={on} onChange={toggle} />
    </FeedbackModalContext.Provider>
  )
}

export const useFeedbackModalContext = () => useContext(FeedbackModalContext)
