'use client'
import { useToggle } from 'react-use'

import { Button } from '../Button'
import { default as Modal } from './Modal'

type IButtonWithModal = {
  buttonText: string
  buttonVariant?: 'primary' | 'secondary'
  children: React.ReactNode
}

export function ButtonWithModal({
  buttonText,
  buttonVariant = 'primary',
  children,
}: IButtonWithModal) {
  const [on, toggle] = useToggle(false)

  return (
    <>
      <Modal title={buttonText} isOpen={on} onChange={toggle}>
        {children}
      </Modal>

      <Button variant={buttonVariant} onClick={toggle}>
        {buttonText}
      </Button>
    </>
  )
}
