import './LocalizedLink.scss'

import { Link } from '@/src/app/config/i18n'

export type ILocalizedLink = {
  children: React.ReactNode
  className?: string
  href: string
  target?: '_blank' | '_self'
  onClick?: (e: React.MouseEvent) => void
  scroll?: boolean
}

const LocalizedLink = ({
  className = '',
  href,
  target,
  scroll = true,
  onClick,
  ...rest
}: ILocalizedLink) => {
  return (
    <Link
      scroll={scroll}
      onClick={onClick}
      href={href}
      target={target}
      className={className}
      {...rest}
    />
  )
}

export default LocalizedLink
