'use client'

import './Image.scss'

import classNames from 'classnames'
import { StaticImport } from 'next/dist/shared/lib/get-img-props'
import NextImage from 'next/image'
import { useState } from 'react'

import { useAspectRatio } from '../../hooks/useAspectRatio'
import { IAspectRatio } from '../../model'
import { useImageLoader } from './model'

export type IImageProps = {
  className?: string
  src?: string | StaticImport | null
  srcMobile?: string | StaticImport | null
  alt: string | null
  quality?: number
  priority?: boolean
  fillParent?: boolean
  loader?: 'next' | 'imgproxy' | 'auto'
  sizes?: string
  unoptimized?: boolean
  aspectRatio: IAspectRatio
}

const Image = ({
  className,
  src,
  srcMobile,
  quality = 75,
  alt,
  priority = false,
  fillParent = false,
  loader = 'auto',
  sizes,
  unoptimized = false,
  aspectRatio,
}: IImageProps) => {
  const { id, styles } = useAspectRatio(aspectRatio)

  const [loaded, setLoadedState] = useState(false)
  const [loadedMobile, setLoadedMobileState] = useState(false)

  const imageLoader = useImageLoader(loader, src)
  const imageLoaderMobile = useImageLoader(loader, srcMobile)

  const imageProps = {
    sizes,
    quality,
    priority,
    unoptimized,
  }

  return (
    <>
      {fillParent && styles}

      <div
        className={classNames('image', {
          [`${className}`]: !!className,
          'image--fill-parent': fillParent,
          'image--loaded': loaded,
          'image--loaded-mobile': loadedMobile,
          'image--with-mobile-image': srcMobile,
        })}
      >
        <div data-id={id} className="image__inner">
          {src && (
            <NextImage
              className="image__desktop"
              onLoad={() => setLoadedState(true)}
              loader={imageLoader}
              src={src}
              width={aspectRatio.width}
              height={aspectRatio.height}
              alt={alt ?? `image-${id}`}
              {...imageProps}
            />
          )}

          {srcMobile && (
            <NextImage
              className="image__mobile"
              onLoad={() => setLoadedMobileState(true)}
              loader={imageLoaderMobile}
              src={srcMobile}
              width={aspectRatio.sm?.width ?? aspectRatio.width}
              height={aspectRatio.sm?.height ?? aspectRatio.height}
              alt={alt ? `${alt}-mobile` : `image-${id}-mobile`}
              {...imageProps}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default Image
