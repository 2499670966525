import deepmerge from 'deepmerge'
import { notFound } from 'next/navigation'
import { Formats } from 'next-intl'
import { createSharedPathnamesNavigation } from 'next-intl/navigation'
import { getRequestConfig } from 'next-intl/server'

import { ILang } from '@/src/shared/model'

export const defaultLocale: ILang = 'en'

export const locales: ILang[] = [defaultLocale, 'es']
export const localePrefix = 'always'

export const formats: Partial<Formats> = {
  number: {
    currency: {
      style: 'currency',
      currency: 'USD',
    },
  },
}

export const { Link, redirect, usePathname, useRouter } = createSharedPathnamesNavigation({
  locales,
  localePrefix,
})

export default getRequestConfig(async ({ locale }) => {
  if (!locales.includes(locale as ILang)) notFound()

  const userMessages = (await import(`../../app/translation/${locale}.json`)).default
  const defaultMessages = (await import(`../translation/en.json`)).default

  return {
    messages: deepmerge(defaultMessages, userMessages),
    formats,
  }
})
