'use client'

import { Button } from '@/src/shared/ui/Button'

import { useFeedbackModalContext } from '../../../Feedback/context'

export type IShowFeedbackModalButtonProps = {
  buttonText: string
  buttonVariant?: 'primary' | 'secondary'
}

const ShowFeedbackModalButton = ({ buttonText, buttonVariant }: IShowFeedbackModalButtonProps) => {
  const { onFeedbackModalChange } = useFeedbackModalContext()

  return (
    <Button variant={buttonVariant} onClick={onFeedbackModalChange}>
      {buttonText}
    </Button>
  )
}

export default ShowFeedbackModalButton
