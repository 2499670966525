'use client'

import './Modal.scss'

import cn from 'classnames'
import { useEffect, useRef } from 'react'
import ReactModal from 'react-modal'
import { clearBodyLocks, lock } from 'tua-body-scroll-lock'

import { isStorybook } from '@/src/app/config/env'
import PlusIcon from '@/src/shared/ui/Icon/assets/plus-icon.svg'

import { Title } from '../Title'

ReactModal.setAppElement('body')

type Props = {
  children: React.ReactNode
  title: string
  isOpen: boolean
  onChange?: (isOpen: boolean) => void
  position?: 'left' | 'right' | 'center'
}

const Modal = ({
  children,
  title,
  isOpen,
  onChange = (val) => !val,
  position = 'center',
}: Props) => {
  const overlayRef = useRef<HTMLElement | null>(null)
  const contentRef = useRef<HTMLElement | null>(null)

  function closeModal() {
    onChange(false)
  }

  function handleAfterOpen() {
    if (overlayRef && overlayRef.current) {
      lock(overlayRef.current)
    }
  }

  function handleAfterClose() {
    clearBodyLocks()
  }

  function handleRequestClose() {
    closeModal()
  }

  function overlayRefCb(node: HTMLDivElement) {
    overlayRef.current = node
  }

  function contentRefCb(node: HTMLDivElement) {
    contentRef.current = node
  }

  function handleClickClose(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()

    closeModal()
  }

  useEffect(() => {
    return () => clearBodyLocks()
  }, [])

  return (
    <>
      <ReactModal
        className={`ws-custom-modal ws-custom-modal--${position}`}
        overlayClassName={`ws-custom-overlay ws-custom-modal--${position}`}
        onAfterOpen={handleAfterOpen}
        onAfterClose={handleAfterClose}
        onRequestClose={handleRequestClose}
        closeTimeoutMS={0}
        contentLabel={title || ''}
        overlayRef={overlayRefCb}
        contentRef={contentRefCb}
        isOpen={isOpen}
      >
        <div className={`${cn('modal', `modal-position--${position}`)}`}>
          <div className={`${cn('modal__inner')}`}>
            <div className={`${cn('modal__header')}`}>
              <div className={`${cn('modal__title')}`}>
                <Title size="l" tag="h2" ellipsis>
                  {title}
                </Title>
              </div>

              <div className={`${cn('modal__button-close')}`}>
                <button onClick={handleClickClose}>
                  {!isStorybook && (
                    // TODO Bug: Broken storybook
                    <PlusIcon viewBox="0 0 39 39" width={28} height={28} name="plus-icon" />
                  )}
                </button>
              </div>
            </div>

            <div className={`${cn('modal__content')}`}>{children}</div>
          </div>
        </div>
      </ReactModal>
    </>
  )
}

export default Modal
