import { useId } from 'react'

import { BreakpointsSm } from '../config/variables'
import { IAspectRatio } from '../model/aspectRatio'

export const useAspectRatio = (aspectRatioConfig: IAspectRatio) => {
  const id = useId()
  const renderStyles = (
    <style
      dangerouslySetInnerHTML={{
        __html: `
              [data-id="${id}"] {
                aspect-ratio: ${aspectRatioConfig.sm?.width ?? aspectRatioConfig.width} / ${aspectRatioConfig.sm?.height ?? aspectRatioConfig.height}
              }

              @media (min-width: ${BreakpointsSm}) {
                [data-id="${id}"] {
                  aspect-ratio: ${aspectRatioConfig.width} / ${aspectRatioConfig.height}
                }
              }
            `,
      }}
    />
  )

  return {
    styles: renderStyles,
    id,
  }
}
