'use client'

import './FeedbackModal.scss'

import dynamic from 'next/dynamic'
import { useTranslations } from 'next-intl'

import { Modal } from '@/src/shared/ui/Modal'

const CreateRequest = dynamic(
  () => import('@/src/features/become-a-partner/ui/CreateRequest').then((mod) => mod.CreateRequest),
  { ssr: false, loading: () => <p>Loading...</p> },
)

export type IFeedbackModal = {
  isOpen: boolean
  onChange: (isOpen: boolean) => void
}

const FeedbackModal = ({ isOpen, onChange }: IFeedbackModal) => {
  const t = useTranslations('Common')

  return (
    <Modal title={t('becomePartner')} isOpen={isOpen} onChange={onChange}>
      <CreateRequest />
    </Modal>
  )
}

export default FeedbackModal
