import './Title.scss'

import cn from 'classnames'

export type ITitleTags = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
export type ITitleSizes = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl'

export type ITitle = {
  children: React.ReactNode
  align?:
    | 'start'
    | 'center'
    | 'inherit'
    | 'initial'
    | 'unset'
    | 'end'
    | 'justify'
    | 'left'
    | 'right'
  tag?: ITitleTags | 'span'
  color?: 'default' | 'inherit'
  size?: ITitleSizes
  weight?: 'm' | 'l' | 'xl' | 'default'
  className?: string
  ellipsis?: boolean
  ellipsisRows?: 'two' | 'three' | 'fourth'
  ellipsisHeightProperty?: 'height' | 'max-height'
  isItalic?: boolean
}

const Title = ({
  children,
  className,
  tag = 'h2',
  color = 'inherit',
  size = 'm',
  weight = 'default',
  align = 'inherit',
  ellipsis = false,
  ellipsisRows,
  ellipsisHeightProperty = 'max-height',
  isItalic = false,
}: ITitle) => {
  const Tag = tag

  return (
    <Tag
      className={`${cn(
        'title',
        `title--tag-${tag}`,
        `title--color-${color}`,
        `title--size-${size}`,
        `title--align-${align}`,
        `title--weight-${weight}`,
        {
          [`${className}`]: !!className,
          [`title--ellipsis-rows-${ellipsisRows}`]: !!ellipsisRows,
          [`title--ellipsis-height-prop-${ellipsisHeightProperty}`]: !!ellipsisRows,
          'title--is-ellipsis': ellipsis && !ellipsisRows,
          'title--is-italic': isItalic,
        },
      )}`}
    >
      {children}
    </Tag>
  )
}

export default Title
